.angular-logo {
  max-width: 90px;
  margin: -50px auto 45px;
  img{
    width: 100%;
  }
}
.presentation-page .title-brand .type{
  top: 120px !important;
}
.carousel-control-prev,
.carousel-control-next{
    background-color: $danger-color;
    border-radius: 50%;
    opacity: 1;
    text-shadow: none;
    font-size: 12px;

    &:hover,
    &:focus{
        opacity: 1;
        background-color: $danger-states-color;
    }
}
.carousel-control-prev-icon{
    background-image: none !important;
    margin-top: -5px;
    &:before{
        color: $white-color;
        font-size: 26px;
        margin: 2px;
        padding-right: 3px;
        font: normal normal normal 23px/1 FontAwesome !important;
        content: "\f104" !important;
    }
}
.carousel-control-next-icon{
    background-image: none !important;
    margin-top: -5px;
    &:before{
        color: $white-color;
        font-size: 26px;
        margin: 2px;
        padding-left: 2px;
        font: normal normal normal 23px/1 FontAwesome !important;
        content: "\f105" !important;
    }
}
.carousel-control-prev{
    height: 30px;
    top: 48%;
    width: 30px;
    left: 20px;
    opacity: 0;
}
.carousel-control-next{
    height: 30px;
    right: 20px;
    top: 48%;
    width: 30px;
    opacity: 0;
}
.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .fa, .carousel-control .fa {
    display: inline-block;
    z-index: 5;
}
.carousel-control-prev .fa{
    font-size: 26px;
    margin: 2px;
    padding-right: 3px;
}
.carousel-control-next .fa{
    font-size: 26px;
    margin: 2px;
    padding-left: 3px;
}
.carousel-control-prev, .carousel-control-next {
    background-image: none;
}

.page-carousel{
   border-radius: $border-radius-extreme !important;
   border: none !important;
   width: 100%;
}

.carousel-inner{
    .carousel-item{
        img{
            border-radius: $border-radius-extreme;
            box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
            width: 100%;
        }
    }
}

.carousel-indicators{
    bottom: 0px;
}
.carousel-indicators li{
    background-color: rgba(204, 204, 204, 0.49);
    border: 0 none;
}
.carousel-indicators .active{
    background-color: $danger-color;
}

.page-carousel:hover{
    .carousel-control-prev,
    .carousel-control-next{
        opacity: 1;
        z-index: 1;
        @include transition(300ms, linear);
    }
}
.page-carousel,
ngb-carousel{
    &:focus{
        outline: none !important;
    }
}
.page-carousel{
    .carousel-control-prev,
    .carousel-control-next{
        opacity: 0;
        z-index: 1;
        @include transition(300ms, linear);
    }
}
